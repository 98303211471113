import {useState, useEffect} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Row} from "react-bootstrap";

export default function EditPlayer() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const {id} = useParams();

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getUser() {
        axios.get(`https://davecode.stronawcal.pl/open/api/player/${id}`).then(function (response) {
            setInputs(response.data);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        axios.put(`https://davecode.stronawcal.pl/open/api/player/${id}/edit`, inputs).then(function (response) {
            navigate('/');
        });

    }
    return (
        <>
            <h5>Edycja zawodnika - <strong>{inputs.name}</strong></h5>
            <div className="form-result">
                <Form onSubmit={handleSubmit}>

                    <Row className="mb-4">
                        <Form.Label>Imię i nazwisko:</Form.Label>
                        <Form.Control
                            name="name"
                            placeholder="Imię"
                            required
                            value={inputs.name}
                            onChange={handleChange}
                        />

                        <Form.Label className="mt-2">Wartość zawodnika:</Form.Label>
                        <Form.Control
                            name="value_player"
                            placeholder="Wartość zawodnika"
                            disabled={true}
                            value={inputs.value_player}
                            onChange={handleChange}
                        />

                        <Form.Label className="mt-2">Telefon:</Form.Label>
                        <Form.Control
                            name="phone"
                            placeholder="Telefon"
                            required
                            value={inputs.phone}
                            onChange={handleChange}
                        />

                        <Form.Label className="mt-2">Uwagi:</Form.Label>
                        <Form.Control
                            name="comments"
                            placeholder="Uwagi"
                            value={inputs.comments}
                            onChange={handleChange}
                        />
                    </Row>

                    <Button variant="primary" type="submit">
                        Edytuj
                    </Button>
                </Form>
            </div>
        </>
    )
}